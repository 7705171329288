import React from "react";
import { ReactComponent as LoaderSvg } from "../../assets/icons/spinner.svg";
import "./Loader.css";

interface LoaderProps {
  isLoading?: boolean;
  isPage?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({
  isLoading = false,
  isPage = false,
}) => {
  return (
    <>
      {isLoading && (
        <div className={isPage ? "loader-new loader-page" : "loader-new"}>
          <LoaderSvg />
        </div>
      )}
    </>
  );
};
